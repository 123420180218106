import * as React from "react";
const SvgUserMgmtIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 43 42"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M21.31 36.782c1.939 0 3.51-1.557 3.51-3.478 0-1.922-1.571-3.479-3.51-3.479-1.94 0-3.511 1.558-3.511 3.479 0 1.92 1.572 3.478 3.51 3.478ZM16.043 42c0-1.38.554-2.704 1.541-3.682a5.318 5.318 0 0 1 3.725-1.535 5.319 5.319 0 0 1 3.725 1.535A5.184 5.184 0 0 1 26.575 42M35.353 36.782c1.938 0 3.51-1.557 3.51-3.478 0-1.922-1.572-3.479-3.51-3.479-1.94 0-3.511 1.558-3.511 3.479 0 1.92 1.572 3.478 3.51 3.478ZM30.088 42c0-1.383.556-2.71 1.545-3.689a5.305 5.305 0 0 1 3.73-1.528 5.304 5.304 0 0 1 3.719 1.535A5.188 5.188 0 0 1 40.62 42M7.267 36.782c1.939 0 3.51-1.557 3.51-3.478 0-1.922-1.571-3.479-3.51-3.479-1.94 0-3.511 1.558-3.511 3.479 0 1.92 1.572 3.478 3.51 3.478ZM2 42c0-1.38.553-2.704 1.539-3.682a5.305 5.305 0 0 1 3.718-1.535c1.4 0 2.74.55 3.73 1.528a5.189 5.189 0 0 1 1.545 3.69M7.268 24.609v-6.957h28.086v6.957M21.309 24.608V12.435M21.31 12.435c2.908 0 5.265-2.336 5.265-5.218C26.575 4.336 24.218 2 21.31 2c-2.908 0-5.266 2.336-5.266 5.217 0 2.882 2.358 5.218 5.266 5.218Z"
    />
  </svg>
);
export default SvgUserMgmtIcon;
