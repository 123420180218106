import * as React from "react";
const SvgIrcIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 45 45"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
      d="M23.029 13.44h-.222M22.916 31.218 23 19M3.182 25.596a20.001 20.001 0 0 0 28.892 14.49l7.534 1.556a2.222 2.222 0 0 0 2.622-2.623l-1.533-7.556a20.003 20.003 0 1 0-37.515-5.867Z"
    />
  </svg>
);
export default SvgIrcIcon;
