import * as React from "react";
const SvgDashboardIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.5}
      d="M12.287 16.95v11.398M20.063 11.496v16.852M27.715 22.973v5.375"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.5}
      d="M27.81 3.323H12.191c-5.444 0-8.857 3.842-8.857 9.28v14.67c0 5.438 3.397 9.28 8.857 9.28h15.62c5.46 0 8.856-3.842 8.856-9.28v-14.67c0-5.438-3.397-9.28-8.857-9.28Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDashboardIcon;
