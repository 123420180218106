import React from "react";
import Select from "../Select/Select";
import TextInput from "../TextInput/TextInput";
import RadioButtonGroup from "../RadioButtonGroup/RadioButtonGroup";
import MultiSelectWrapper from "../MultiSelect/MultiSelect";
import DatePicker from "../DatePicker/DatePicker";
import TextareaInput from "../TextareaInput/TextareaInput";
import Checkbox from "../Checkbox/Checkbox";

const FormHandler = (props) => {
  const { fields, columns } = props;
  const generateFieldbyType = (field) => {
    let component;

    const { type } = field;
    switch (type) {
      case "input":
        component = <TextInput {...field} {...props} />;
        break;
      case "radio":
        component = (
          <RadioButtonGroup
            name={field.name}
            register={props.register}
            errors={props.errors}
            mandatory={field.mandatory}
            data={field.data}
            label={field.label}
          />
        );
        break;
      case "select":
        component = <Select {...field} {...props} />;
        break;
      case "multi_select":
        component = <MultiSelectWrapper {...field} {...props} />;
        break;
      case "text_area":
        component = <TextareaInput {...field} {...props} />;
        break;
      case "checkbox":
        component = <Checkbox {...field} {...props} />;
        break;
      case "date":
        component = <DatePicker {...field} {...props} />;
        break;
      default:
        break;
    }
    return component;
  };

  const styles = {
    columnCount: columns,
    columnGap: "30%", // sets the size of the gap between columns
    display: "grid",
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gap: "0.9rem",
  };

  return (
    <div style={styles}>
      {fields?.map((field) => (
        <div
          style={{ paddingLeft: "1.2rem", paddingRight: "1.2rem" }}
          key={field.name}
        >
          {generateFieldbyType(field)}
        </div>
      ))}
    </div>
  );
};
export default FormHandler;
