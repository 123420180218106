import React from "react";
import "./Select.css";

const Select = ({
  label,
  options,
  name,
  register,
  errors,
  mandatory,
  disabled,
}) => {
  const { onChange, ...params } = register(name);
  return (
    <div className="select__regs--form">
      <label className="form__label">
        {label}
        {mandatory && <span className="asterisk">*</span>}
      </label>
      <select
        className={`
        ${disabled && "disabled__field"}
        ${
          errors[name] ? "form-select form__select--errorborder" : "form-select"
        }`}
        {...params}
        onChange={onChange}
        id={name}
        disabled={disabled}
      >
        <option
          selected
          className="text-muted selectfields__regs--form"
          value=""
        >
          Select option
        </option>
        {options?.map((option) => (
          <option
            key={option.value}
            value={option.value}
            className="selectfields__regs--form"
          >
            {option.label}
          </option>
        ))}
      </select>
      <p className="error__message">{errors[name]?.message}</p>
    </div>
  );
};
export default Select;
