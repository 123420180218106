import {
  AssetMgmt,
  DashboardIcon,
  DeviceIcon,
  EventMgmtIcon,
  GisMappingIcon,
  IrcIcon,
  UserMgmtIcon,
  VideoMgmtIcon,
} from "../Components/IconComponents";
import {
  assetMgmtUrl,
  convergeUrl,
  devicesUrl,
  eventUrl,
  gisUrl,
  ircUrl,
  userMgmtUrl,
  vmsUrl,
} from "../Services/EndPoints";

export const modules = [
  {
    name: "dashboard",
    widgetname: "Dashboards",
    icon: (
      <DashboardIcon
        width="1.4rem"
        height="1.4rem"
        className="icon__container"
        stroke="var(--color-E)"
      />
    ),
    projectlink: convergeUrl,
    text: "Integrate any system to ingest data from FTP, HTTP or Database and generate and view Dashboards & Reports",
  },
  {
    name: "devices",
    widgetname: "Devices",
    icon: (
      <DeviceIcon
        width="1.6rem"
        height="1.6rem"
        className="icon__container"
        stroke="var(--color-E)"
      />
    ),
    projectlink: devicesUrl,
    text: "View dashboards and manage all IoT devices including Smart Light, Environment Sensors, Public Announcement System, Emergency Call Box etc...",
  },
  {
    name: "event_mgmt",
    widgetname: "Event Management",
    icon: (
      <EventMgmtIcon
        width="1.4rem"
        height="1.4rem"
        className="icon__container"
        stroke="var(--color-E)"
      />
    ),
    projectlink: eventUrl,
    text: "Manage events and incident, create SoPs, Generate Alert and Notifications and execute SoP",
  },
  {
    name: "asset_mgmt",
    widgetname: "Asset Management",
    icon: (
      <AssetMgmt
        width="1.4rem"
        height="1.4rem"
        className="icon__container"
        stroke="var(--color-E)"
      />
    ),
    projectlink: assetMgmtUrl,
    // projectlink: "http://192.168.0.3:10004",

    text: "Manage IoT assets and monitor the assets for availability",
  },
  {
    name: "irc",
    widgetname: "Information Resource Center",
    icon: (
      <IrcIcon
        width="1.4rem"
        height="1.4rem"
        className="icon__container"
        stroke="var(--color-E)"
      />
    ),
    projectlink: ircUrl,
    text: "Manage all your knowledge base in form of documents, presentations and data sheets at one location for search and access",
  },
  {
    name: "video_management",
    widgetname: "Video Management",
    icon: (
      <VideoMgmtIcon
        width="1.5rem"
        height="1.5rem"
        stroke="var(--color-E)"
        className="icon__container"
      />
    ),
    projectlink: vmsUrl,
    text: "Add surveillance cameras to record and stream for live monitoring, playback recorded videos etc...",
  },
  {
    name: "gis_mapping",
    widgetname: "GIS Mapping",
    icon: (
      <GisMappingIcon
        width="1.4rem"
        height="1.4rem"
        className="icon__container"
        stroke="var(--color-E)"
      />
    ),
    projectlink: gisUrl,
    text: "View and search geo spatial information using a GIS Map",
  },
  {
    name: "user_management",
    widgetname: "User Management",
    icon: (
      <UserMgmtIcon
        width="1.4rem"
        height="1.4rem"
        className="icon__container"
        stroke="var(--color-E)"
      />
    ),
    projectlink: userMgmtUrl,
    text: "Manage users, roles and permissions for the converge application suite",
  },
];
