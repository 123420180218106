import * as React from "react";
const SvgFilledDownArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 27 26"
    {...props}
  >
    <path
      fill={props.fill ? props.fill : "#4899B7"}
      d="M19.466 8.778H6.604c-1.043 0-1.565 1.245-.826 1.975l5.627 5.559c.902.89 2.369.89 3.27 0l2.14-2.114 3.488-3.445c.727-.73.206-1.975-.837-1.975Z"
    />
  </svg>
);
export default SvgFilledDownArrow;
