import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import "./PreviewFile.css";
import { useSelector } from "react-redux";
import { Rejectedicon } from "../../../../Components/IconComponents";
import LMSButton from "../../../LMSButton/LMSButton";

const PreviewFile = (props) => {
  const { title, show, previewHandler, file } = props;
  const { presentTheme } = useSelector((state) => state.appSettings);

  const handleDragStart = (e) => {
    e.preventDefault(); // Disable drag behavior on the image element
  };
  const preventClose = (e) => {
    e.stopPropagation(); // Prevent the click event from propagating
  };
  return (
    <>
      <Modal
        show={show}
        onHide={previewHandler}
        centered
        id={presentTheme}
        size="lg"
        className="modal__header--images"
        backdrop="static" // Prevent modal from closing on backdrop click
        // keyboard={false} // Prevent modal from closing on ESC key press
      >
        <Modal.Header className="image__modal ">
          <Modal.Title className="preview_title">{title}</Modal.Title>
          <Rejectedicon
            onClick={previewHandler}
            width="1.5em"
            height="1.5em"
            className="cursor-pointer"
          />
        </Modal.Header>
        <Modal.Body>
          <img
            className="preview__image"
            src={
              file?.id
                ? `http://192.168.0.3:9080/lms/media/file/${file.id}`
                : URL.createObjectURL(file)
            }
            alt="Preview"
            onDragStart={handleDragStart} // Disable drag behavior
            onClick={preventClose} // Prevent modal from closing on image click
          />
          <div className="preview__filename">{file?.name}</div>
        </Modal.Body>
        <Modal.Footer className=" text-center">
          <LMSButton
            className="btn"
            label="Close"
            type="button"
            onClick={previewHandler}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PreviewFile;
