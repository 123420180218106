import React from "react";
import "./TextareaInput.css";

const TextareaInput = ({
  name,
  errors,
  register,
  label,
  type,
  mandatory,
  value,
}) => {
  const { onChange, ...registerParams } = register(name);

  return (
    <div className="input_text-form">
      <label>
        {label}
        {mandatory && <span className="asterisk">*</span>}
      </label>
      <textarea
        autoComplete="off"
        className={`form-control form-control-sm ${
          errors[name] ? "error__border--color" : "form__input--area"
        }`}
        type={type}
        onChange={(event) => {
          onChange(event);
        }}
        {...registerParams}
      />
      <div className="error__message">{errors[name]?.message}</div>
    </div>
  );
};

export default TextareaInput;
