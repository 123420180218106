import { useEffect, useState } from "react";
import { userApi } from "../Services/ProfileServices";

const useLoading = (value) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const requestInterceptor = userApi.interceptors.request.use((config) => {
      setLoading(true);
      return config;
    });

    const responseInterceptor = userApi.interceptors.response.use(
      (response) => {
        setLoading(false);
        return response;
      },
      (error) => {
        setLoading(false);
        return Promise.reject(error);
      }
    );

    return () => {
      userApi.interceptors.request.eject(requestInterceptor);
      userApi.interceptors.response.eject(responseInterceptor);
    };
  }, [userApi]);

  return loading;
};

export default useLoading;
