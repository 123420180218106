import * as React from "react";
const SvgLogoutIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 22"
    {...props}
  >
    <path
      fill="#4899B7"
      d="M0 4.317V17.3c0 1.195.498 2.257 1.295 3.087.797.797 1.827 1.229 3.022 1.229h6.508v-2.69H4.317c-.897 0-1.594-.73-1.594-1.626V4.317c0-.897.697-1.594 1.594-1.594h6.508V0H4.317C3.122 0 2.092.465 1.295 1.262.498 2.092 0 3.122 0 4.317Zm7.139 3.918v5.18c0 .598.531 1.096 1.13 1.096h6.009v4.084c0 .365.2.664.531.83.133.033.266.033.333.033a.804.804 0 0 0 .598-.233l7.803-7.803c.365-.299.332-.897 0-1.228l-7.803-7.77c-.466-.499-1.461-.2-1.461.598v4.117h-6.01c-.598 0-1.129.498-1.129 1.095l-.001.001Z"
    />
  </svg>
);
export default SvgLogoutIcon;
