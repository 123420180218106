import React from "react";
import "./TextInput.css";

const TextInput = (props) => {
  const {
    errors,
    register,
    name,
    label,
    type,
    mandatory,
    placeholder,
    disabled,
    maxLength,
    upperCase = false,
  } = props;
  const { onChange, ...registerParams } = register(name);
  const upperCaseStyle = { textTransform: "uppercase" };
  return (
    <div className="input_text-form">
      <label>
        {label}
        {mandatory && <span className="asterisk">*</span>}
      </label>
      <input
        autoComplete="off"
        className={`form-control form-control-sm 
        ${disabled && "disabled__field"}
         ${errors[name] ? "error__border--color" : "form__input"}`}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        onChange={(event) => {
          onChange(event);
        }}
        style={upperCase ? upperCaseStyle : {}}
        maxLength={maxLength}
        {...registerParams}
      />
      <div className="error__message">{errors[name]?.message}</div>
    </div>
  );
};

export default TextInput;
