import * as React from "react";
const SvgLight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 169 102"
    {...props}
  >
    <rect
      width={166.537}
      height={99.889}
      x={1.331}
      y={0.899}
      fill="currentColor"
      stroke="#17A3A0"
      rx={4.5}
    />
    <path
      fill="#F3F6F9"
      d="M1.831 6.4a5 5 0 0 1 5-5h33.074v98.888H6.83a5 5 0 0 1-5-5V6.4Z"
    />
    <rect
      width={28.555}
      height={9.889}
      x={6.59}
      y={9.971}
      fill="#E2EBFB"
      rx={4.944}
    />
    <rect
      width={28.555}
      height={6.593}
      x={6.59}
      y={36.34}
      fill="#E2EBFB"
      rx={2}
    />
    <rect
      width={28.555}
      height={6.593}
      x={6.59}
      y={47.877}
      fill="#E2EBFB"
      rx={2}
    />
    <rect
      width={28.555}
      height={6.593}
      x={6.59}
      y={59.416}
      fill="#E2EBFB"
      rx={2}
    />
    <path
      fill="#F3F6F9"
      d="M50.456 1.4h111.911a5 5 0 0 1 5 5v8.514H50.457V1.4ZM50.456 100.288h111.911a5 5 0 0 0 5-5v-8.515H50.457v13.515Z"
    />
  </svg>
);
export default SvgLight;
