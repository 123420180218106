import * as React from "react";
const SvgGisMappingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 42 42"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      d="M33.857 16.714a12.857 12.857 0 0 0-25.715 0c0 5.275 4.22 12.149 12.857 20.383 8.638-8.234 12.858-15.108 12.858-20.383ZM20.999 41C10.522 31.477 5.285 23.38 5.285 16.714a15.714 15.714 0 1 1 31.429 0c0 6.666-5.237 14.763-15.715 24.286Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      d="M21 18.144a2.857 2.857 0 1 0 0-5.714 2.857 2.857 0 0 0 0 5.714ZM21 21a5.714 5.714 0 1 1 0-11.429 5.714 5.714 0 0 1 0 11.429Zm15.429 8.571L41 41.001H28.143v-2.857H13.857V41H1l4.571-11.429H36.43Zm-3.078 0H8.65L5.22 38.144h31.56l-3.429-8.572Z"
    />
  </svg>
);
export default SvgGisMappingIcon;
