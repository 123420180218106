import * as React from "react";
const SvgSuccessSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <circle cx={20} cy={20} r={20} fill="#1EAF2C" />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
      d="M13 19.625 18.259 25 30 13"
    />
  </svg>
);
export default SvgSuccessSvg;
