import React, { useEffect, useState, useRef } from "react";
import Popover from "react-bootstrap/Popover";
import Overlay from "react-bootstrap/Overlay";
import { useDispatch, useSelector } from "react-redux";
import "./ProfileDropdown.css";
import { useNavigate } from "react-router-dom";
import {
  setLocalizationLanguage,
  setPresentTheme,
} from "../../../CentralStore/ExportLMSTable/dataSlice";
import {
  Dark,
  DefaultThemeIcon,
  FilledDownArrow,
  Light,
  LogoutIcon,
  ProfileIcon,
  TickCircle,
} from "../../../Components/IconComponents";
import { appserveraddr } from "../../../Services/EndPoints";
import { useKeycloak } from "@react-keycloak/web";

const ProfileDropdown = () => {
  const [showPopover, setShowPopover] = useState(false);
  const { keycloak } = useKeycloak();

  const [target, setTarget] = useState(null);
  const { presentTheme, localizationLanguage } = useSelector(
    (state) => state.data
  );
  const ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [themeOptions, setThemeOptions] = useState([]);
  const [localizationOptions, setLocalizationOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("localizationLanguage") || localizationLanguage || "en"
  );

  useEffect(() => {
    const themeOptions = [
      {
        value: "light",
        label: "Light Theme",
      },
      {
        value: "dark",
        label: "Dark Theme",
      },
    ];
    const languagelanguage = [
      {
        value: "en",
        label: "English",
      },
      {
        value: "hi",
        label: "Hindi",
      },
      {
        value: "od",
        label: "Odia",
      },
      {
        value: "te",
        label: "Telugu",
      },
    ];
    setThemeOptions(themeOptions);
    setLocalizationOptions(languagelanguage);
    // getData(themeOptionsMasterData).then((res) => setThemeOptions(res));
    // getData(localizationMasterData).then((res) => setLocalizationOptions(res));
  }, []);

  const handleClick = (event) => {
    setShowPopover((prevState) => !prevState);
    setTarget(event.target);
  };

  const handleLanguageChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedLanguage(selectedValue);
    localStorage.setItem("localizationLanguage", selectedValue);
    dispatch(setLocalizationLanguage(selectedValue));
  };

  useEffect(() => {
    const handleWindowClick = (event) => {
      if (showPopover && ref.current && !ref.current.contains(event.target)) {
        setShowPopover(false);
      }
    };
    window.addEventListener("click", handleWindowClick);
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [showPopover]);

  const getThemeIcon = (themeValue) => {
    switch (themeValue) {
      case "light":
        return <Light height="3em" width="6em" fill="#ffffff" />;
      case "dark":
        return <Dark height="3em" width="6em" fill="#ffffff" />;
      default:
        return (
          <DefaultThemeIcon height="3em" width="6em" fill="var(--color-A)" />
        );
    }
  };
  const signoutHandler = () => {
    localStorage.clear();
    keycloak.logout({ redirectUri: appserveraddr });
  };
  return (
    <div className="p-1 profile__dropdown cursor-pointer" ref={ref}>
      <FilledDownArrow
        fill="var(--color-A)"
        height="1.3em"
        width="1.3em"
        onClick={handleClick}
      />

      <Overlay
        show={showPopover}
        target={target}
        placement="bottom"
        container={ref}
      >
        <Popover id="popover-contained">
          <Popover.Body>
            {/* {localizationOptions?.length > 0 ? (
              <>
                <div className="localization__container--profile">
                  <div className="section__title--profile">Language</div>
                  <div className="localization__optionsprofile">
                    {localizationOptions.map((item) => (
                      <label
                        key={item.value}
                        className="localization__radio-labelprofile"
                      >
                        <input
                          type="radio"
                          name="language"
                          value={item.value}
                          className="localization__radio-inputprofile"
                          checked={selectedLanguage === item.value}
                          onChange={handleLanguageChange}
                        />
                        <span className="localization__custom-radioprofile"></span>
                        <span className="localization__option-labelprofile mx-1">
                          {item.label}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
                <hr />
              </>
            ) : (
              ""
            )} */}
            {themeOptions?.length > 0 ? (
              <>
                <div className="section__title--profile">Theme</div>
                <div className="themepopup__profile">
                  {themeOptions.map((theme) => (
                    <div key={theme.value} className="theme__card-wrapper">
                      <div
                        className={`theme__card ${
                          presentTheme === theme.value ? "selected-theme" : ""
                        }`}
                        onClick={() => dispatch(setPresentTheme(theme.value))}
                      >
                        {getThemeIcon(theme.value)}
                        {presentTheme === theme.value && (
                          <div className="tick-overlay">
                            <TickCircle width="1rem" height="1rem" />
                          </div>
                        )}
                      </div>
                      <span className="theme__font--profile">
                        {theme.label}
                      </span>
                    </div>
                  ))}
                </div>
                <hr />
              </>
            ) : (
              ""
            )}
            <div
              className="profile__section  mt-3"
              onClick={() => {
                navigate("/userProfile");
              }}
            >
              <div>
                <ProfileIcon width="1.5rem" height="1.5rem" />
              </div>
              <div>
                <span className="profile-label">Profile</span>
              </div>
            </div>
            <hr />
            <div className=" profile__section mt-3" onClick={signoutHandler}>
              <div>
                <LogoutIcon width="1.5rem" height="1.5rem" />
              </div>
              <div className="profile-label">logout</div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

export default ProfileDropdown;
