import * as React from "react";
const SvgDefaultThemeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 148 89"
    {...props}
  >
    <rect
      width={147.892}
      height={88.348}
      fill={props.fill ? props.fill : "#000"}
      rx={4}
    />
  </svg>
);
export default SvgDefaultThemeIcon;
