import React, { useState, useEffect } from "react";
import "./Checkbox.css";

const Checkbox = ({ values, checkboxheading, listItems, onChangeHandler }) => {
  const getInitialCheckboxState = () => {
    return values.map(({ name }) => listItems.includes(name));
  };

  const [checkboxState, setCheckboxState] = useState(getInitialCheckboxState());

  useEffect(() => {
    setCheckboxState(getInitialCheckboxState());
  }, [listItems]);

  const handleCheckboxChange = (e) => {
    const { checked, value } = e.target;
    const index = values.findIndex((item) => item.name === value);
    if (index !== -1) {
      const updatedCheckboxState = [...checkboxState];
      updatedCheckboxState[index] = checked;
      setCheckboxState(updatedCheckboxState);

      const updatedListItems = updatedCheckboxState
        .map((checked, index) => (checked ? values[index].name : null)).filter(Boolean);
      onChangeHandler(updatedListItems);
    }
  };

  return (
    <div className="checkbox-heading">
      <ul className="checkbox-list">
        {values?.map(({ name }, index) => {
          return (
            <li key={index}>
              <div className="container">
                <label htmlFor={`custom-checkbox-${index}`}>
                  {name}
                  <input
                    type="checkbox"
                    id={`custom-checkbox-${index}`}
                    name={name}
                    value={name}
                    checked={checkboxState[index]}
                    onChange={handleCheckboxChange} // Handle checkbox change
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Checkbox;
