import React from "react";
import "./CellDetails.css";
import { getFormattedDate } from "../../Components/UiComponents/utils";
import FileView from "../Common/Form/FileView/FileView";

const CellDetails = ({ item }) => {
  const { label, value, type } = item;

  const getFormattedValue = () => {
    if (value) {
      if (label === "Registered On") {
        return getFormattedDate(value);
      } else if (
        type === "input" ||
        type === "select" ||
        type === "multi_select"
      ) {
        return value;
        // } else if (type === "multi_select") {
        //   return extractValues();
      } else if (type === "file_upload") {
        let fileData = Array.isArray(value) ? item?.value[0] : item?.value;
        return <FileView fileData={fileData} />;
      } else if (type === "date") {
        return getFormattedDate(value);
      }
    }
  };

  return (
    <div className="pb-4">
      <div className="view__details--label">{label}</div>
      <div className="view__details--value">
        {getFormattedValue() || <span className="text-secondary">-NA-</span>}
      </div>
    </div>
  );
};

export default CellDetails;
