import React from "react";
import "./LMSButton.css";

const variantStyles = {
  primary: {
    backgroundColor: "#951b1d", //#18a3a0 green colored button for tableheader and addButtonLabel
    color: "var(--color-E)",
  },
  secondary: {
    backgroundColor: "var(--color-C)", //#979797 grey colored button
    color: "var(--color-E)",
  },
  success: {
    backgroundColor: "var(--green2-color)", //#40b2b7 used for submit button green type
    color: "var(--color-E)",
  },
  active: {
    backgroundColor: "var(--color-G)", //#40b2b7 used for submit button green type
    color: "var(--color-E)",
  },
  success2: {
    backgroundColor: "var(--green2-text-color)", //#007934 mostly used for whitelist button
    color: "var(--color-E)",
  },
  warning: {
    backgroundColor: "var(--color-G)", //#ffa100
    color: "var(--color-E)",
  },
  danger: {
    backgroundColor: "var(--red-color)", //#E95060 mostly used for blacklist button
    color: "var(--color-E)",
  },
  light: {
    backgroundColor: "var(--color-E)", //#E95060 mostly used for blacklist button
    color: "var(--color-B)",
  },
};

const LMSButton = ({
  label, //mandatory
  onClick, //mandatory
  key = "",
  className = "",
  type = "button",
  style = {},
  customId,
  disabled = false,
  variant = "primary",
  size = "btn_lg", //.btn_sm,btn_xlg,btn_vsm,btn_vvsm
  href = null,
  ref,
  scrollToTop = false,
  buttonEffect = false,
  ...restOfProps
}) => {
  const buttonStyle = {
    ...variantStyles[variant],
    ...style,
    opacity: disabled ? 0.8 : 1,
  };
  const effectClass = buttonEffect ? "effect" : "";
  if (href) {
    return (
      <a
        href={href}
        type={type}
        key={key}
        style={buttonStyle}
        className={`lms_btn ${size} ${className} ${variant}`}
        {...restOfProps}
      >
        {label}
      </a>
    );
  }

  return (
    <>
      <button
        type={type}
        key={key}
        onClick={onClick}
        style={buttonStyle}
        disabled={disabled}
        id={customId}
        ref={ref}
        className={`lms_btn ${size} ${className} ${variant} ${effectClass}`}
        {...restOfProps}
      >
        {label}
      </button>
    </>
  );
};

export default LMSButton;
