import React, { useState, useEffect } from "react";
import "./LMSHeader.css";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";
import {
  Menu,
  Dataview,
  Goback,
  Reload,
} from "../../Components/IconComponents";
import LMSButton from "../LMSButton/LMSButton";
import { setTableData } from "../../CentralStore/ExportLMSTable/dataSlice";

const height = "1.2rem";
const width = "1.2rem";

const LMSHeader = (props) => {
  const {
    title,
    vehicleNumber,
    subName,
    status,
    showIconsForback,
    showicons,
    showmenu,
    titleStyle = {},
    fileName,
    date,
    buttonLable,
    showSearch,
    showDatePicker,
  } = props;
  const navigate = useNavigate();
  const [showImport, setShowImport] = useState(false);
  const dispatch = useDispatch();

  const dataFromStore = useSelector((state) => state.data);

  useEffect(() => {
    return () => {
      dispatch(setTableData({ columns: [], tableData: [] }));
    };
  }, []);

  const printScreen = () => {
    printElement(document.getElementById("printThisBulkModal"));
  };
  function printElement(elem) {
    var domClone = elem.cloneNode(true);

    var $printSection = document.getElementById("printSection");

    if (!$printSection) {
      var $printSection = document.createElement("div");
      $printSection.id = "printSection";
      document.body.appendChild($printSection);
    }

    $printSection.innerHTML = "";
    $printSection.appendChild(domClone);
    window.print();
  }

  // const handleOpenModal = () => {
  //   setShowModal(true);
  // };
  // const handleCloseModal = () => {
  //   setShowModal(false);
  // };

  const importpop = () => {
    setShowImport(!showImport);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <Menu />
    </a>
  ));

  return (
    <div className="lms__header">
      <div className="lms__title">
        {showIconsForback && (
          <span style={{ marginRight: "10px" }}>
            <Goback
              className="cursor-pointer"
              height={height}
              width={width}
              onClick={() => navigate(-1)}
            />
          </span>
        )}
        <span style={titleStyle}>{title}</span> {vehicleNumber}
      </div>

      {date && <div>{date}</div>}
      {(subName || status) && (
        <div className="lms__title">
          {subName}
          {status === "In Transit" ? (
            <span className="lms__status--transit">{status}</span>
          ) : (
            <span className="lms__status--delivery">{status}</span>
          )}
        </div>
      )}
      {(showicons || showmenu || showImport || buttonLable) && (
        <span className="lms__title_icon">
          {showicons && (
            <span className="lms__icons">
              <Reload />
            </span>
          )}
          {showicons && (
            <span className="lms__icons">
              <Dataview />
            </span>
          )}
          {showmenu && (
            <Dropdown>
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-basic"
              ></Dropdown.Toggle>
              <div className="dropdown__menu--header">
                <Dropdown.Menu>
                  <CSVLink
                    data={dataFromStore.tableData}
                    // data={modifiedData}
                    // data={formatDataForExport(dataFromStore.tableData)}
                    columns={dataFromStore.columns}
                    filename={fileName}
                    id="ExportTableData"
                    style={{ color: "var(--bluish3-color)" }}
                    // encoding="UTF-8"
                  >
                    <Dropdown.Item as="button">Export</Dropdown.Item>
                  </CSVLink>
                </Dropdown.Menu>
              </div>
            </Dropdown>
          )}

          {buttonLable && (
            <>
              <LMSButton
                label="Printing of Dispatch Slips"
                size="btn_sm"
                onClick={printScreen}
                // onClick={handleOpenModal}
              />
              {/* <BulkModal showModal={showModal} handleClose={handleCloseModal} /> */}
            </>
          )}
        </span>
      )}
    </div>
  );
};
export default LMSHeader;
