import React, { useEffect, useState } from "react";
import "./PersonalDetails.css";
import { formConfig } from "./PersonalDetailsConfig";
import { useForm } from "react-hook-form";
import Delayed from "../../../Components/UiComponents/Delayed/Delayed";
import { appendFormData } from "../../../Components/UiComponents/utils";
import { useDispatch } from "react-redux";
import ChangePasswordComp from "./ChangePasswordComp";
import { fetchProfileData } from "../../../CentralStore/ExportLMSTable/dataSlice";
import { authUserData } from "../../../Services/EndPoints";
import DetailsHandler from "../../DetailsHandler/DetailsHandler";
import FormHandler from "../../Common/Form/FormHandler/FormHandler";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import { putDataFormDataWithNoidPf } from "../../../Services/ProfileServices";
import LMSButton from "../../LMSButton/LMSButton";
import lmsToast from "../../CustomToastifier/CustomToastifier";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const formSchema = yup.object({
  phone: yup
    .string()
    .nullable()
    .required("Please provide  the phone number")
    .matches(/^(?:\+91|0)?[6789]\d{9}$/, "Please provide a valid phone number")
    .max(10, "Phone number must be at most 10 digits"),
  firstName: yup
    .string()
    .required("Please provide the first name")
    .min(3, "First name must be at least 3 characters long")
    .max(50, "First name cannot exceed 50 characters")
    .matches(/^[A-Za-z\s]+$/, "Please provide a valid first name"),
  lastName: yup
    .string()
    .required("Please provide the last name")
    .min(3, "Last name must be at least 3 characters long")
    .max(50, "Last name cannot exceed 50 characters")
    .matches(/^[A-Za-z\s]+$/, "Please provide a valid last name"),
  email: yup
    .string()
    .required("Please provide  the Email Id")
    .email("Please provide a valid Email Id"),
});
export default function PersonalDetails({ userData }) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formSchema), mode: "all" });

  const [isEdit, setIsEdit] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setValue("firstName", userData?.firstName);
    setValue("middleName", userData?.middleName);
    setValue("lastName", userData?.lastName);
    setValue("phone", userData?.phone);
    setValue("email", userData?.email);
  }, [userData]);

  const toggleEdit = () => {
    setIsEdit((prevState) => !prevState);
  };

  const togglePopup = () => {
    setOpenPopup(!openPopup);
  };

  const GenerateForm = (fields, register, errors) => {
    return (
      <Delayed>
        <FormHandler
          register={register}
          errors={errors}
          fields={fields}
          columns={2}
        />
      </Delayed>
    );
  };

  const submitHandler = (data) => {
    const formData = new FormData();
    if (userData?.id) {
      appendFormData(data, formData);
      putDataFormDataWithNoidPf(authUserData, formData).then((res) => {
        if (res?.response?.status === 400) {
          setTimeout(() => {
            lmsToast({ toasterType: "error", msg: res?.response?.data });
          }, 300);
          dispatch(fetchProfileData());
          toggleEdit();
        } else {
          setTimeout(() => {
            lmsToast({ toasterType: "success", operationType: "edit" });
          }, 300);
          dispatch(fetchProfileData());
          toggleEdit();
        }
      });
    }
  };

  const handleSaveClick = () => {
    handleSubmit(submitHandler)();
  };
  return (
    <>
      <form>
        {isEdit ? (
          <div className="mt-3 profile_form">
            <div className="profile-form_label active__title">
              Personal Details
            </div>
            <React.Fragment>
              <div>{GenerateForm(formConfig, register, errors)}</div>
            </React.Fragment>
          </div>
        ) : (
          <DetailsHandler
            title="Personal Details"
            columns={2}
            config={formConfig}
            details={userData}
          />
        )}
        <div className="profile_button">
          {isEdit ? (
            <LMSButton
              label="Save Profile"
              type="button"
              className="profile_button--save"
              onClick={handleSaveClick}
            />
          ) : (
            <LMSButton
              label="Edit Profile"
              type="button"
              className="profile_button--edit"
              onClick={toggleEdit}
            />
          )}
          <LMSButton
            label="Change Password"
            className="profile_button--warning"
            onClick={togglePopup}
          />
        </div>

        {openPopup && (
          <Confirmpopup
            title="Change Password"
            show={openPopup}
            handleToggle={togglePopup}
            messageComp={<ChangePasswordComp togglePopup={togglePopup} />}
          />
        )}
      </form>
    </>
  );
}
