import * as React from "react";
const SvgVideoMgmtIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 54 54"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.409 11.157 49.466 21.54l-3.62 3.624 2.51.676-2.329 8.693-7.525-2.016-3.719 3.724-9.297-2.553-5.23 9.06-.006-.002v.004l-9-.002v4.502h-4.5v-13.5h4.5v4.498h6.406L21 32.457 6.75 28.543l4.659-17.387Zm3.176 5.53-2.331 8.7 21.194 5.82 7.357-7.368-26.22-7.151Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgVideoMgmtIcon;
