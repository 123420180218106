import React from "react";
import "./RadioButtonGroup.css";
const RadioButtonGroup = ({
  name,
  data,
  register,
  errors,
  label,
  mandatory,
}) => {
  return (
    <div className="radio_div">
      <label className="label_radio">
        {label}
        {mandatory && <span className="asterisk">*</span>}
      </label>
      <div>
        {data?.map((option, i) => (
          <div className="radio__item ">
            <input
              key={i}
              type="radio"
              name={name}
              id={option.value}
              value={option.value}
              {...register(name)}
            />
            <label htmlFor={option.value}>{option.label}</label>
          </div>
        ))}
      </div>
      <p className="error__message error_radio">{errors[name]?.message}</p>
    </div>
  );
};

export default RadioButtonGroup;
