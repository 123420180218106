import * as React from "react";
const SvgDeviceIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      fill="currentColor"
      d="M42.864 29.083a6.582 6.582 0 0 1 2.584.89h.069a21 21 0 0 0-.847-11.626 1.39 1.39 0 0 0-1.778-.82 1.389 1.389 0 0 0-.82 1.778 18.319 18.319 0 0 1 .792 9.778ZM36.572 39.695a18.056 18.056 0 0 1-8.43 3.917 1.393 1.393 0 1 0 .235 2.778h.25a21.056 21.056 0 0 0 10.014-4.723v-.097a6.485 6.485 0 0 1-2.07-1.875ZM21.693 43.529a18.321 18.321 0 0 1-5.958-2.236 21.442 21.442 0 0 1-2.264-1.542 6.514 6.514 0 0 1-2.028 1.917c.902.76 1.864 1.448 2.875 2.055a21.387 21.387 0 0 0 6.862 2.584h.263a1.394 1.394 0 1 0 .25-2.778ZM6.331 29.377c.28-.022.56-.063.834-.125a18.056 18.056 0 0 1 .75-9.806 1.39 1.39 0 1 0-2.612-.958 20.833 20.833 0 0 0-.777 11.708 6.626 6.626 0 0 1 1.805-.82Z"
    />
    <path
      fill="currentColor"
      d="M44.306 31.945a4.445 4.445 0 0 0-3.375-.583 4.542 4.542 0 0 0-1.944 1.07l-5.375-3.112a9.541 9.541 0 0 0-3.848-11.708l-.152-.097a9.167 9.167 0 0 0-3.361-1.125v-5.278a4.472 4.472 0 0 0 2.61-2.084 4.583 4.583 0 0 0-1.569-6.25 4.375 4.375 0 0 0-3.36-.444 4.5 4.5 0 0 0-2.779 2.097 4.583 4.583 0 0 0 1.57 6.195c.317.21.663.374 1.027.486v5.278A9.22 9.22 0 0 0 17 20.945a9.515 9.515 0 0 0-.583 8.334l-5.555 3.18a5.265 5.265 0 0 0-.625-.472 4.446 4.446 0 0 0-6.07 1.639 4.583 4.583 0 0 0 1.528 6.194 4.457 4.457 0 0 0 2.292.639c.365.003.73-.044 1.083-.139a4.5 4.5 0 0 0 3.18-5.708l5.39-3.111a9.807 9.807 0 0 0 2.624 2.375 9.014 9.014 0 0 0 12.125-2.361l5.32 3.069a4.57 4.57 0 0 0 2.014 5.056c.686.418 1.474.639 2.278.639a4.403 4.403 0 0 0 3.833-2.209 4.583 4.583 0 0 0-1.528-6.125ZM9.723 36.973a1.945 1.945 0 0 1-1.264.889 1.888 1.888 0 0 1-1.514-.209 2.055 2.055 0 0 1-.694-2.777 1.944 1.944 0 0 1 1.222-.917c.153-.043.312-.067.472-.07.37.002.73.107 1.042.306a2.056 2.056 0 0 1 .736 2.778ZM23.306 5.68a1.943 1.943 0 0 1 1.222-.916c.154-.044.313-.067.473-.07.352.002.697.098 1 .278a2.055 2.055 0 0 1 .694 2.778 1.944 1.944 0 0 1-1.222.916A1.889 1.889 0 0 1 24 8.46a2.055 2.055 0 0 1-.694-2.778Zm3.347 26.834a6.653 6.653 0 0 1-5.11-.75 7.042 7.042 0 0 1-2.404-9.542A6.778 6.778 0 0 1 25 18.848a6.68 6.68 0 0 1 3.417.93 7.042 7.042 0 0 1 2.403 9.542 6.751 6.751 0 0 1-4.167 3.195Zm17.014 4.277c-.268.46-.708.794-1.222.93a1.888 1.888 0 0 1-1.472-.221 2.056 2.056 0 0 1-.695-2.778 1.93 1.93 0 0 1 1.25-1c.157-.014.315-.014.473 0 .337-.003.67.089.958.264a2.056 2.056 0 0 1 .708 2.805ZM11.179 13.887a18.056 18.056 0 0 1 7.43-5.236 6.944 6.944 0 0 1-.236-2.875 21.041 21.041 0 0 0-9.305 6.264 1.402 1.402 0 1 0 2.11 1.848Z"
    />
    <path
      fill="currentColor"
      d="M34.26 10.043a18.206 18.206 0 0 1 4.528 3.847 1.39 1.39 0 0 0 2.11-1.805 20.835 20.835 0 0 0-5.222-4.445 22.042 22.042 0 0 0-4.083-1.847c.14.957.068 1.934-.208 2.861.994.384 1.956.849 2.875 1.389Z"
    />
  </svg>
);
export default SvgDeviceIcon;
