import Keycloak from "keycloak-js";

const hostname = window.location.hostname;
const protocol = window.location.protocol;

let url;

if (hostname === "localhost") {
  url = process.env.REACT_APP_URM_BASE_URL;
} else {
  url = `${protocol}//${hostname}/urm`;
}

const keycloak = new Keycloak({
  url: url,
  realm: process.env.REACT_APP_URM_REALM,
  clientId: process.env.REACT_APP_URM_REALM_CLIENT,
});

export default keycloak;
