import * as React from "react";
const SvgAssetMgmt = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 42 42"
    {...props}
  >
    <path
      fill="currentColor"
      d="M38.613 19.286v-2.002c0-.745-.6-1.345-1.345-1.345h-3.112a13.716 13.716 0 0 0-1.564-3.776l2.204-2.204a1.345 1.345 0 0 0 0-1.904l-2.828-2.828a1.345 1.345 0 0 0-1.904 0L27.86 7.43a13.614 13.614 0 0 0-3.776-1.564V2.763c0-.745-.6-1.345-1.346-1.345h-3.995c-.745 0-1.345.6-1.345 1.345v3.112a13.716 13.716 0 0 0-3.776 1.564l-2.196-2.196a1.345 1.345 0 0 0-1.904 0l-2.828 2.82a1.345 1.345 0 0 0 0 1.904l2.204 2.204a13.718 13.718 0 0 0-1.564 3.776H4.222c-.745 0-1.345.6-1.345 1.346v1.993h8.679a9.196 9.196 0 0 1 9.197-9.197c5.08 0 9.19 4.124 9.19 9.197h8.67Z"
    />
    <path
      fill="currentColor"
      d="M26.392 19.286a5.647 5.647 0 1 0-11.296 0 5.647 5.647 0 0 0 5.648 5.648 5.647 5.647 0 0 0 5.648-5.648ZM12.236 22.252a.564.564 0 0 0-.568-.567H6.733a.564.564 0 0 0-.567.567v8.176H3.848a.564.564 0 0 0-.567.567v3.33a3.273 3.273 0 0 1 1.135 0v-2.762h2.317a.564.564 0 0 0 .568-.568V22.82h4.367a.564.564 0 0 0 .568-.567ZM3.85 40.063a2.326 2.326 0 1 0-.001-4.65 2.326 2.326 0 0 0 0 4.65ZM10.414 40.063a2.326 2.326 0 1 0 0-4.65 2.326 2.326 0 0 0 0 4.65Z"
    />
    <path
      fill="currentColor"
      d="M13.208 25.129h-2.787a.564.564 0 0 0-.567.567v8.63a3.273 3.273 0 0 1 1.134 0v-8.063h2.22a.564.564 0 0 0 .568-.567.569.569 0 0 0-.568-.567ZM17.35 40.063a2.326 2.326 0 1 0-.001-4.651 2.326 2.326 0 0 0 0 4.651ZM17.916 34.326v-7.634a.564.564 0 0 0-.567-.567.564.564 0 0 0-.568.567v7.634a3.283 3.283 0 0 1 1.135 0ZM37.074 34.326a3.272 3.272 0 0 1 1.134 0v-3.33a.564.564 0 0 0-.567-.568h-2.318v-8.176a.564.564 0 0 0-.567-.567h-4.935a.564.564 0 0 0-.567.567c0 .316.251.567.567.567h4.368v8.176c0 .316.251.568.567.568h2.318v2.763ZM37.64 40.063a2.326 2.326 0 1 0 0-4.65 2.326 2.326 0 0 0 0 4.65ZM31.076 40.063a2.326 2.326 0 1 0 0-4.65 2.326 2.326 0 0 0 0 4.65Z"
    />
    <path
      fill="currentColor"
      d="M30.509 34.326a3.272 3.272 0 0 1 1.134 0v-8.63a.564.564 0 0 0-.567-.567H28.28a.564.564 0 0 0-.567.567c0 .316.251.567.567.567h2.22v8.063h.009ZM24.14 40.063a2.326 2.326 0 1 0 0-4.65 2.326 2.326 0 0 0 0 4.65ZM23.572 26.692v7.634a3.281 3.281 0 0 1 1.135 0v-7.634a.564.564 0 0 0-.567-.567.574.574 0 0 0-.568.567Z"
    />
  </svg>
);
export default SvgAssetMgmt;
