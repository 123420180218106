import * as React from "react";
const SvgDark = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 167 100"
    {...props}
  >
    <rect
      width={165.537}
      height={98.889}
      x={0.731}
      y={0.399}
      fill="#495057"
      rx={4}
    />
    <path
      fill="#5C6268"
      d="M.731 5.4a5 5 0 0 1 5-5h33.074v98.888H5.73a5 5 0 0 1-5-5V5.4Z"
    />
    <rect
      width={28.555}
      height={9.889}
      x={5.49}
      y={8.971}
      fill="#6D7277"
      rx={4.944}
    />
    <rect
      width={28.555}
      height={6.593}
      x={5.49}
      y={35.34}
      fill="#6D7277"
      rx={2}
    />
    <rect
      width={28.555}
      height={6.593}
      x={5.49}
      y={46.877}
      fill="#6D7277"
      rx={2}
    />
    <rect
      width={28.555}
      height={6.593}
      x={5.49}
      y={58.416}
      fill="#6D7277"
      rx={2}
    />
    <path
      fill="#5C6268"
      d="M49.356.4h111.911a5 5 0 0 1 5 5v8.514H49.357V.4ZM49.356 99.288h111.911a5 5 0 0 0 5-5v-8.515H49.357v13.515Z"
    />
  </svg>
);
export default SvgDark;
