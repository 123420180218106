import { Routes, Route } from "react-router-dom";
import CommonDashboard from "../Pages/CommonDashboard/CommonDashboard";
import UserProfile from "../Pages/UserProfile/UserProfile";

const Router = () => {
  return (
    <Routes>
      <Route index element={<CommonDashboard />}></Route>
      <Route path="/common-dashboard" element={<CommonDashboard />}></Route>
      <Route path="/userProfile" element={<UserProfile />}></Route>
    </Routes>
  );
};

export default Router;
