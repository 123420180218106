import html2canvas from "html2canvas";
import moment from "moment";
import { managemaster } from "../../Services/EndPoints";
import { getData } from "../../Services/Services";

//This function will download a screenshot a div with ref
export const downLoadScreenShot = (ref, scale, fileName, hideZoomControls) => {
  if (hideZoomControls) {
    let control = document.querySelector(".leaflet-control-zoom");
    control.setAttribute("data-html2canvas-ignore", "true");
  }
  html2canvas(ref.current, {
    useCORS: true,
    backgroundColor: null,
    scale: scale,
    windowHeight: ref.current.scrollHeight,
    windowWidth: ref.current.scrollWidth,
  }).then((canvas) => {
    const a = document.createElement("a");
    a.href = canvas.toDataURL();
    a.download = fileName;
    a.click();
  });
};
export function isNotNullOrEmptyOrUndefined(value) {
  return value !== null && value !== undefined && value !== "";
}

//  export const getFormattedTableDate = (date) => moment(date).format("DD/MM/YYYY");
// // This function will convert the data into array of strings
export const getStringFormatData = (data) => {
  return data.map((item) => item.value).join(",");
};

//This function is used for Append THe data of Images and Text

// This function will take submittedData and formConfig as parameter and
// return the catergoried data based on the tabs also adds the Registered On for the first tab.

export const isURMEnabled = () => {
  if (
    process.env.REACT_APP_URM_ENABLED &&
    process.env.REACT_APP_URM_ENABLED === "true"
  ) {
    return true;
  } else {
    return false;
  }
};

//This function will take date object as parameter and return the date DD/MM/YYYY
export const getFormattedDate = (date, format = "DD/MM/YYYY") => {
  if (date) {
    return moment.utc(date).format(format);
  } else {
    return "NA";
  }
};

//This function is used for Append THe data of Images and Text

export const appendFormData = (data, formData, additionalExclusions = []) => {
  const defaultExclusions = ["registration_date", "deleted", "id"];
  const excludedKeys = [...defaultExclusions, ...additionalExclusions];

  for (const key in data) {
    if (excludedKeys.includes(key)) {
      // Skip this key if it's in the excludedKeys array
      continue;
    } else {
      formData.append(key, data[key]);
    }
  }
};

export const getMasterDataByCategory = (catagory) => {
  return getData(managemaster + "/" + catagory)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const toAcronym = (phrase) => {
  return phrase
    .split(" ")
    .map((word) => word[0])
    .join("")
    .toUpperCase();
};
