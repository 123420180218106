import React from "react";
import { Link } from "react-router-dom";
import { modules } from "../../../JsonData/commondashboardData";
import "./GridGenerator.css";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

const GridGenerator = () => {
  const { allModules, allModulesStatus } = useSelector((state) => state.data);

  const Widget = ({ item, disabled }) => {
    return (
      <div div className="module-item">
        <div key={item.name} style={{ opacity: disabled === true ? 0.5 : 1 }}>
          <div className="module-name d-flex">
            {/* <span className="icon__container">{item.icon}</span> */}
            <div className="static__icon">{item.icon}</div>

            <span className="mx-2 align-items-center justify-content-center d-flex">
              {item.widgetname}
            </span>
          </div>
          <div className="module-description">{item.text}</div>
        </div>
      </div>
    );
  };

  const gridItems = modules?.map((item) => {
    // if (allModules?.includes(item.widgetname)) {
    // return (
    //   <Link
    //     to={item?.projectlink}
    //     key={item.name}
    //     className="module-item-container"
    //     reloadDocument
    //     disabled={true}
    //   >
    //     <Widget item={item} disabled={false} />
    //   </Link>
    // );
    // }

    // else
    return (
      <div className="module-item-container" key={item.name}>
        <Link
          to={item?.projectlink}
          key={item.name}
          className="module-item-container"
          reloadDocument
        >
          <Widget item={item} disabled={false} />
        </Link>
      </div>
    );
  });

  // if (allModules?.length > 0)
  return <div className="modules-container">{gridItems}</div>;
  if (allModulesStatus === "loading" || allModulesStatus === "failed")
    return (
      <div className="d-flex align-items-center justify-content-center w-100 h-100">
        {allModulesStatus === "loading" && <Spinner animation="border" />}
        {allModulesStatus === "" && <h3>Failed to get permissions !</h3>}
      </div>
    );
};

export default GridGenerator;
