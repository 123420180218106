import React, { useEffect } from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Router from "./Routes/Router";
import { useDispatch, useSelector } from "react-redux";
import { setPresentTheme } from "./CentralStore/ExportLMSTable/dataSlice";
import Header from "./Layout/Header/Header";

function App() {
  const dispatch = useDispatch();
  const { presentTheme } = useSelector((state) => state.data);
  useEffect(() => {
    dispatch(setPresentTheme(getTheme()));
  }, []);
  useEffect(() => {
    if (presentTheme) {
      localStorage.setItem("common_dashboard_theme", presentTheme);
    }
  }, [presentTheme]);
  const getTheme = () => {
    return presentTheme
      ? presentTheme
      : localStorage.getItem("common_dashboard_theme")
      ? localStorage.getItem("common_dashboard_theme")
      : "light";
  };
  return (
    <div id={getTheme()} className="main__container">
      <Header />
      <Router />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
