import React, { useState, useEffect } from "react";
import "./Header.css";
import Notifications from "../../Components/UiComponents/Notifications/Notifications";
import { getUserImage, userMgmtService } from "../../Services/EndPoints";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isNotNullOrEmptyOrUndefined } from "../../Components/UiComponents/utils";
import { ConvergeLogo, SettingsIcon } from "../../Components/IconComponents";
import ThemesPopUp from "../../Components/UiComponents/ThemesPopUp/ThemesPopUp";
import ProfileDropdown from "../../Pages/Common/ProfileDropdown/ProfileDropdown";

const Header = () => {
  const { profileData } = useSelector((store) => store.data);
  const [avtarImg, setAvtarImg] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    let id = profileData?.photo?.id;
    if (isNotNullOrEmptyOrUndefined(id))
      setAvtarImg(userMgmtService + getUserImage + id);
  }, [profileData]);

  const getUserName = () => {
    if (
      profileData !== null &&
      profileData?.firstName &&
      profileData?.lastName
    ) {
      return `${profileData.firstName} ${
        profileData?.middleName ? profileData?.middleName : ""
      } ${profileData.lastName}`;
    } else return "Not Signed In";
  };

  return (
    <div className="header_position">
      <div className="logo_position">
        <ConvergeLogo
          height="4em"
          width="12em"
          fill="var(--converge-logo-color)"
        />
      </div>

      <div className="header_nav-right">
        {/* <div className="popup--container">
          <ThemesPopUp />
        </div> */}
        <div className="popup--container">
          <Notifications />
        </div>
        {/* <div className="popup--container ">
          <SettingsIcon className="p-1" height="2.1em" width="2.1em" />
        </div> */}
        <div
          className="profile cursor-pointer"
          onClick={() => {
            navigate("/userProfile");
          }}
        >
          <img src={avtarImg} className="avatar" />
        </div>
        <div className="user-details">
          <span className="user-name">
            <div>{getUserName()}</div>
          </span>
          <span className="user-role">
            {profileData?.department || "Not Available"}
          </span>
        </div>
        <ProfileDropdown />
      </div>
    </div>
  );
};

export default Header;
