import React from "react";
import "./CommonDashboard.css";
import GridGenerator from "../Common/GridGenerator/GridGenerator";
import mineBanner from "../../Assets/Images/converge_banner.svg";
import bankLogo from "../../Assets/Images/bank_logo.svg";

const CommonDashboard = () => {
  return (
    <div className="main__container">
      <div className="cd__content">
        <div className="cd__banner">
          <img src={mineBanner} className="converge__banner" />
          <div className="banner__overlay">
            <div className="banner__text">
              <img src={bankLogo} className="mx-2" />
              The Bihar State Cooperative Bank Ltd
            </div>
          </div>
        </div>
        <div className="cd__cards">
          <GridGenerator />
        </div>
      </div>
    </div>
  );
};

export default CommonDashboard;
