import React from "react";
import "./Notification.css";

import { Fire } from "../../../IconComponents";

const Notification = (data) => {
  return (
    <div className="dropdown-content">
      <ul className="notify-drop">
        {data.data.length > 0 &&
          data.data.map((item, index) => (
            <li key={index}>
              <div className="row">
                <div className="col-md-2 col-sm-2 col-xs-2">
                  <div
                    className="dropdown-content__roundedicon"
                    style={{
                      background:
                        item?.type === "primary" ? "#FF4D2C" : "#FFA100",
                    }}
                  ></div>
                </div>
                <div className="col-md-10 col-sm-10 col-xs-10 p-0">
                  <div
                    className="dropdown-content__title cursor-pointer"
                    style={{
                      color: item?.type === "primary" ? "#FF4D2C" : "#FFA100",
                      textDecorationColor:
                        item?.type === "primary" ? "#FF4D2C" : "#FFA100",
                    }}
                  >
                    {item?.title}
                  </div>
                  <div className="dropdown-content__details">
                    {item?.details}
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Notification;
