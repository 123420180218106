import React, { useEffect, useState } from "react";
import GridItem from "../../Components/UiComponents/GridItem/GridItem";
import { GridStack } from "gridstack";
import ProfileImageComponent from "./ProfileImageComponent/ProfileImageComponent";
import PersonalDetails from "./PersonalDetails/PersonalDetails";
import { authDetailsConfig } from "./PersonalDetails/PersonalDetailsConfig";
import { useSelector } from "react-redux";
import DetailsHandler from "../DetailsHandler/DetailsHandler";
import LMSHeader from "../LMSHeader/LMSHeader";

export default function UserProfile() {
  const [userData, setUserData] = useState(null);
  const { profileData } = useSelector((store) => store.data);

  useEffect(() => {
    GridStack.init();
    setUserData(profileData);
  }, [profileData]);

  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: <LMSHeader title="User Profile" showIconsForback={true} />,
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 4,
      height: 11,
      id: 2,
      component: <ProfileImageComponent profileData={userData} />,
    },
    {
      x: 4,
      y: 1,
      width: 8,
      height: 4,
      id: 2,
      component: (
        <DetailsHandler
          title="Authorized Details"
          details={userData}
          columns={2}
          config={authDetailsConfig}
        />
      ),
    },
    {
      x: 4,
      y: 5,
      width: 8,
      height: 7,
      id: 2,
      component: <PersonalDetails userData={userData} />,
    },
  ];
  return (
    <div className="grid-stack grid-stack-instance-1 ">
      <GridItem layoutData={layoutData} />
    </div>
  );
}
