import React from "react";
import Select from "react-select";
import "./MultiSelect.css";
import { Controller } from "react-hook-form";

const MultiSelectWrapper = ({
  options,
  errors,
  name,
  label,
  mandatory,
  control,
  isMulti = true,
}) => {
  const customStyles = {
    // option: (provided, state) => ({
    //   ...provided,
    //   color: state.isSelected ? "var(--white3-color)" : "var(--color-B)",
    // }),
    option: (provided, state) => ({
      ...provided,
      width: "100%",
      zIndex: 1000,
      color: state.isFocused
        ? document?.getElementById("contrast")
          ? "#ffffff"
          : "var(--white3-color)"
        : state.isSelected
        ? document?.getElementById("contrast")
          ? "#ffffff"
          : "var(--white3-color)"
        : "var(--color-B)",
      background: state.isFocused
        ? document?.getElementById("contrast")
          ? "#951b1d"
          : "var( --color-A)"
        : provided.background,
      ":hover": {
        background: document?.getElementById("contrast")
          ? "#951b1d"
          : "var( --color-A)",
        color: document?.getElementById("contrast")
          ? "#ffffff"
          : "var(--color-E)",
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      background: document?.getElementById("contrast")
        ? "#951b1d"
        : "var(--color-D)",
    }),

    control: (base, state) => ({
      ...base,
      background: "var(--color-E)",
      fontSize: "12px",
      borderColor: state.isFocused
        ? "var(--color-G)"
        : errors && errors[name]
        ? "var(--color-H)"
        : "var(--white3-color)",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "var(--color-G)" : "var(--white3-color)",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: document?.getElementById("contrast")
        ? "#D9D9D9"
        : "var(--color-E)",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: document?.getElementById("contrast")
        ? "#ffffff"
        : "var(--red-color)",
      ":hover": {
        background: document?.getElementById("contrast")
          ? "#951b1d"
          : "var(--color-E)",
        color: document?.getElementById("contrast")
          ? "#ffffff"
          : "var(--red-color)",
      },
    }),
    // dropdownIndicator: (base) => ({
    //   ...base,
    //   color: "var(var( --color-A))", // Custom colour
    // }),
    dropdownIndicator: (base) => ({
      ...base,
      color: document?.getElementById("contrast")
        ? "#252B48"
        : "var(--color-E)",
      "&:hover": {
        color: document?.getElementById("contrast")
          ? "#252B48"
          : "var(--color-E)",
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: document?.getElementById("contrast")
        ? "#951b1d"
        : "var(--red-color)",
      ":hover": {
        color: document?.getElementById("contrast")
          ? "#951b1d"
          : "var(--red-color)",
        cursor: "pointer",
      },
    }),
  };

  return (
    <div className="multiselect__regs--form">
      <label className="form__label">
        {label} {mandatory && <span className="asterisk">*</span>}
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Select
            styles={customStyles}
            placeholder="Select option"
            classNamePrefix="react-select"
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: document?.getElementById("contrast")
                  ? "#951b1d"
                  : "var( --color-A)",
                neutral50: "var(--white3-color)",
                neutral80: document?.getElementById("contrast")
                  ? "#ffffff"
                  : "var(--white3-color)",
                primary: "var(--color-B)",
              },
            })}
            value={value}
            onChange={onChange}
            options={options}
            isMulti={isMulti}
            isSearchable={true}
          />
        )}
      />

      <p className="error__message">{errors[name]?.message}</p>
    </div>
  );
};

export default MultiSelectWrapper;
