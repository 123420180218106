import * as React from "react";
const SvgProfileIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 30"
    {...props}
  >
    <path
      fill="#4899B7"
      fillRule="evenodd"
      d="M18.757 7.595c0 4.792-2.898 8.905-6.756 8.905-3.862 0-6.757-4.113-6.757-8.907C5.244 2.802 7.736 0 12 0c4.264 0 6.756 2.8 6.756 7.595ZM.142 27.212C.72 27.9 3.209 30 12 30c8.791 0 11.279-2.1 11.859-2.785a.624.624 0 0 0 .135-.476C23.862 25.416 22.67 19.5 12 19.5 1.33 19.5.139 25.416.005 26.739a.624.624 0 0 0 .137.474Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgProfileIcon;
