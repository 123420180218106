import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  appserveraddr,
  authUserData,
  getAllPermissions,
  getAppModules,
  getMineDetails,
} from "../../Services/EndPoints";
import { getDataPf } from "../../Services/ProfileServices";
import axios from "axios";
import { toAcronym } from "../../Components/UiComponents/utils";

const initialRegState = {
  pan_image: null,
  upload_vehicle_picture: null,
};

export const fetchProfileData = createAsyncThunk(
  "profileData/fetchProfileData",
  async () => {
    try {
      const response = await getDataPf(authUserData);
      return response;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchAllModules = createAsyncThunk(
  "allModules/fetchAllModules",
  async () => {
    try {
      const response = await getDataPf(getAppModules);
      return response;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchMineDetails = createAsyncThunk(
  "mineDetails/fetchMineDetails",
  async (token) => {
    try {
      const response = await axios.get(`${appserveraddr}${getMineDetails}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchPermissions = createAsyncThunk(
  "permissions/fetchPermissions",
  async () => {
    try {
      const response = await getDataPf(getAllPermissions);
      return response;
    } catch (error) {
      console.error(error);
    }
  }
);

const dataSlice = createSlice({
  name: "data",
  initialState: {
    columns: [],
    registrationFiles: initialRegState,
    tableData: [],
    profileData: null,
    presentTheme: null,
    profileDataStatus: "idle",
    permissions: null,
    permissionsStatus: "idle",
    allModules: null,
    allModulesStatus: "idle",
    mineDetails: null,
    mineNameShort: "",
    mineDetailsStatus: "idle",
    localizationLanguage: "en",
  },
  reducers: {
    setLocalizationLanguage: (state, action) => {
      const { payload } = action;
      state.localizationLanguage = payload;
    },
    setTableData: (state, action) => {
      state.columns = action.payload.columns;
      state.tableData = action.payload.tableData;
    },
    setPresentTheme: (state, action) => {
      const { payload } = action;
      state.presentTheme = payload;
    },
    setRegFilesToStore: (state, actions) => {
      const { type, file } = actions.payload;
      if (type === "reset") {
        state.registrationFiles = initialRegState;
      } else {
        state.registrationFiles[type] = file;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllModules.pending, (state) => {
        state.allModulesStatus = "loading";
      })
      .addCase(fetchAllModules.fulfilled, (state, action) => {
        state.allModulesStatus = "succeeded";
        state.allModules = action.payload;
      })
      .addCase(fetchAllModules.rejected, (state, action) => {
        state.allModulesStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchProfileData.pending, (state) => {
        state.profileDataStatus = "loading";
      })
      .addCase(fetchProfileData.fulfilled, (state, action) => {
        state.profileDataStatus = "succeeded";
        state.profileData = action.payload;
      })
      .addCase(fetchProfileData.rejected, (state, action) => {
        state.profileDataStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchMineDetails.pending, (state) => {
        state.mineDetailsStatus = "loading";
      })
      .addCase(fetchMineDetails.fulfilled, (state, action) => {
        state.mineDetailsStatus = "succeeded";
        state.mineDetails = action.payload;
        const mineDet = action.payload;
        // state.mineNameShort = toAcronym(mineDet[0]?.mines_name);
      })
      .addCase(fetchMineDetails.rejected, (state, action) => {
        state.mineDetailsStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchPermissions.pending, (state) => {
        state.permissionsStatus = "loading";
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.permissionsStatus = "succeeded";

        state.permissions = action.payload;
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.permissionsStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  setTableData,
  setRegFilesToStore,
  setLocalizationLanguage,
  setPresentTheme,
} = dataSlice.actions;
export default dataSlice.reducer;
