import React from "react";
import { useState } from "react";
import PreviewFile from "../../Form/PreviewFile/PreviewFile";
import {
  Docicon,
  Jpgicon,
  Pdficon,
} from "../../../../Components/IconComponents";

const FileView = ({ fileData }) => {
  const [ispreview, setIspreview] = useState(false);
  const previewHandler = () => setIspreview(() => !ispreview);

  // console.log(fileData);

  // A function to convert the bytes in to other size units
  const convertFileSize = (bytes) => {
    const sizes = ["B", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 B";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
  };

  // A funtion to return the icon based on the file type
  const generateIconByType = (type) => {
    let icon;
    switch (type) {
      case "image/png" || "image/jpg" || "image/jpeg":
        icon = <Jpgicon />;
        break;
      case "application/pdf":
        icon = <Pdficon />;
      case "application/doc":
        icon = <Docicon />;
      default:
        icon = <Pdficon />;
        break;
    }
    return icon;
  };

  return (
    <div>
      <div
        className="file__detail--box cursor-pointer"
        onClick={() => {
          previewHandler();
        }}
      >
        <div className="d-flex">
          <div className="file_icon">{generateIconByType(fileData.type)}</div>
          <div className="file__detail">
            <div
              className={fileData.name?.length > 10 && "file__name--ellipsis"}
            >
              {fileData.name}
            </div>
            <div>{convertFileSize(fileData.size)}</div>
          </div>
          {ispreview && (
            <PreviewFile file={fileData} show={ispreview} title="Preview" />
          )}
        </div>
      </div>
    </div>
  );
};

export default FileView;
