const { hostname, protocol } = window.location;

let tempUserMgmtService;
let tempAppServerAddr;
let convergeTemp;
let devicesTemp;
let eventTemp;
let assteMgmtTemp;
let ircTemp;
let vmsTemp;
let gisTemp;
let userMgmtTemp;

if (hostname === "localhost") {
  tempUserMgmtService = process.env.REACT_APP_USER_BASE_URL;
  tempAppServerAddr = process.env.REACT_APP_APP_SERVER_ADDRESS;
  convergeTemp = process.env.REACT_APP_USER_BASE_URL + "/client/converge";
  devicesTemp = process.env.REACT_APP_USER_BASE_URL + "/client/iccc";
  eventTemp = process.env.REACT_APP_USER_BASE_URL + "/client/event-management";
  assteMgmtTemp =
    process.env.REACT_APP_USER_BASE_URL + "/client/asset-management";
  ircTemp = process.env.REACT_APP_USER_BASE_URL + "/client/irc";
  vmsTemp = process.env.REACT_APP_USER_BASE_URL + "/client/vms";
  gisTemp = process.env.REACT_APP_USER_BASE_URL + "/client/gis";
  userMgmtTemp =
    process.env.REACT_APP_USER_BASE_URL + "/client/user-management";
} else {
  tempUserMgmtService = `${protocol}//${hostname}`;
  tempAppServerAddr = `${protocol}//${hostname}`;
  convergeTemp = `${protocol}//${hostname}` + "/client/converge";
  devicesTemp = `${protocol}//${hostname}` + "/client/iccc";
  eventTemp = `${protocol}//${hostname}` + "/client/event-management";
  assteMgmtTemp = `${protocol}//${hostname}` + "/client/asset-management";
  ircTemp = `${protocol}//${hostname}` + "/client/irc";
  vmsTemp = `${protocol}//${hostname}` + "/client/vms";
  gisTemp = `${protocol}//${hostname}` + "/client/gis";
  userMgmtTemp = `${protocol}//${hostname}` + "/client/user-management";
}

export const baseUrl = ""; //update whenever required
export const appserveraddr = tempAppServerAddr;
export const deviceBaseUrl = ""; //update whenever required
export const userMgmtService = tempUserMgmtService;
export const authBaseUrl = tempUserMgmtService;
export const convergeUrl=convergeTemp;
export const devicesUrl=devicesTemp;
export const eventUrl=eventTemp;
export const assetMgmtUrl=assteMgmtTemp;
export const ircUrl=ircTemp;
export const vmsUrl=vmsTemp;
export const gisUrl=gisTemp;
export const userMgmtUrl=userMgmtTemp;

//permissions
export const getAllPermissions = "/auth/permissions/permissions";
export const managemaster = "/lms/master-data";
export const getMineDetails = "/lms/mines/mines";

export const getAppModules = "/auth/permissions/modules";

//user image
export const getUserImage = "/auth/media/file/";
export const postImage = "/auth/profile/change_picture";
export const authUserData = "/auth/profile";
export const vehicleChecklistReports = "/lms/vehicle_inspection/report";
export const changePassword = "/auth/profile/change_password";
