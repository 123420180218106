import { BarsLoader } from "../../IconComponents";

const Loader = ({ show, size = "medium" }) => {
  const getLoaderSize = () => {
    let loader;
    switch (size) {
      case "small":
        loader = "1.2rem";
        break;
      case "medium":
        loader = "2.5rem";
        break;
      case "large":
        loader = "4rem";
        break;
      default:
        loader = "3rem";
        break;
    }
    return loader;
  };
  return (
    show && (
      <div className="w-100 h-100 d-flex align-items-center position-absolute make-it-center LMS__loader">
        <BarsLoader
          fill="var(--white3-color)"
          width={getLoaderSize()}
          height={getLoaderSize()}
        />
      </div>
    )
  );
};

export default Loader;
