import axios from "axios";
import { appserveraddr, deviceBaseUrl } from "./EndPoints";

const baseUrl = appserveraddr;

export const api = axios.create({
  baseURL: appserveraddr,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("accessToken");
};

api.interceptors.request.use(
  (config) => {
    const token = getTokenFromLocalStorage();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export async function getData(endPoint) {
  try {
    const response = await api.get(baseUrl + endPoint);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error, endPoint);
    return [];
  }
}

export async function postDataWithBody(endPoint, body) {
  try {
    const response = await api.post(deviceBaseUrl + endPoint, body);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error, endPoint);
    return [];
  }
}

export async function deleteData(endPoint, id) {
  try {
    const response = await api.delete(endPoint);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function editData(endPoint, id) {
  try {
    const response = await api.put(endPoint);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function fileUploadService(endPoint, formData) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  try {
    const response = await api.post(deviceBaseUrl + endPoint, formData, config);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error, endPoint);
    return [];
  }
}
