import * as yup from "yup";

export const formConfig = [
  {
    name: "firstName",
    label: "First Name",
    type: "input",
    mandatory: "true",
  },
  {
    name: "middleName",
    label: "Middle Name",
    type: "input",
  },
  {
    name: "lastName",
    label: "Last Name",
    type: "input",
    mandatory: "true",
  },
  {
    name: "phone",
    label: "Mobile Number",
    type: "input",
    mandatory: "true",
  },
  {
    name: "email",
    label: "Email Id",
    type: "input",
    mandatory: "true",
  },
];

export const authDetailsConfig = [
  {
    name: "username",
    label: "User Name",
    type: "input",
  },
  {
    name: "realmRoles",
    label: "Role Name",
    type: "input",
  },
  {
    name: "department",
    label: "Department Name",
    type: "input",
  },
];

export const chngPwSchema = yup.object({
  old_password: yup.string().required("Please provide the Old Password"),
  new_password: yup
    .string()
    .required("Please provide new password")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/,
      "Password must contain at least one uppercase letter, one numeric digit, one special character, and be 8-15 characters long"
    ),
  retype_password: yup
    .string()
    .required("Please provide the Confirm Password")
    .oneOf([yup.ref("new_password")], "Password must match"),
});
