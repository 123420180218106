import * as React from "react";
const SvgTickCircle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 34 34"
    {...props}
  >
    <path
      fill="#17A3A0"
      d="M16.999 31.168c7.791 0 14.166-6.375 14.166-14.167S24.79 2.834 17 2.834c-7.792 0-14.167 6.375-14.167 14.167s6.375 14.167 14.167 14.167Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m10.979 17 4.009 4.01 8.032-8.018"
    />
  </svg>
);
export default SvgTickCircle;
