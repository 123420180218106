import React from "react";
import moment from "moment";
import ViewDetails from "../ViewDetails/ViewDetails";
import "./DetailsHandler.css";

const DetailsHandler = ({
  title = "Trip Details :",
  columns = 4,
  config,
  externalClass,
  details,
}) => {
  const getFormattedDetails = () => {
    const formattedData = [];

    for (let i = 0; i < config.length; i++) {
      let name = config[i].name;
      if (details?.hasOwnProperty(name)) {
        let val = details[name];
        if (
          name === "valid_till_date" ||
          name === "entry_gate_timestamp" ||
          name === "driving_license_expiry_date" ||
          (name === "timestamp" && details[name])
        ) {
          val = moment(details[name]).format("DD/MM/YYYY HH:mm:ss");
        }
        config[i].value = val;
      }
      formattedData.push(config[i]);
    }
    return formattedData;
  };
  return (
    <div className={`grid-item mt-2 ${externalClass}`}>
      <div className="px-3 mt-4  active__title detailshandler__title d-flex justify-content-between">
        <div> {title}</div>
      </div>
      <div className="details__container">
        <ViewDetails data={getFormattedDetails(details)} columns={columns} />
      </div>
    </div>
  );
};

export default DetailsHandler;
